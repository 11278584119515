import React, { Component } from "react";
import { List, Avatar } from "antd";
import { onDownload } from "../utils/utils";

class Resume extends Component {
  render() {
    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        return (
          <div key={education.school}>
            <h3>{education.school}</h3>
            <p className="info">
              {education.degree} <span>&bull;</span>
              <em className="date">{education.graduated}</em>
            </p>
            <p>{education.description}</p>
          </div>
        );
      });
      var work = this.props.data.work.map(function (work) {
        return (
          <div key={work.company}>
            <h3>{work.company}</h3>
            <p className="info">
              <span>{work.title}</span>
              <span>&bull;</span> <em className="date">{work.years}</em>
            </p>
            <p>
              {work.testimonials && (
                <div>
                  <h4
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      marginLeft: 5,
                      fontSize: 16,
                    }}
                  >
                    Testimonials
                  </h4>
                  <div>
                    {work.testimonials.map((testimonial) => (
                      <a
                        onClick={() =>
                          onDownload(
                            "kjo-anbefaling-naviair.pdf",
                            "./kjo-anbefaling-naviair.pdf"
                          )
                        }
                        className="button"
                        style={{
                          color: "#fff",
                          fontSize: 12,
                          padding: 20,
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                      >
                        <i className="fa fa-download" /> {testimonial.name}
                      </a>
                    ))}
                  </div>
                </div>
              )}
              <h4
                style={{
                  color: "rgba(0,0,0,0.5)",
                  marginLeft: 5,
                  fontSize: 16,
                }}
              >
                Achievements
              </h4>

              <List
                itemLayout="horizontal"
                dataSource={work.achievements}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor: item.avatarBkg
                              ? item.avatarBkg
                              : undefined,
                          }}
                          src={item.img}
                        />
                      }
                      title={
                        item.title ? (
                          <a href={item.link ? item.link : undefined}>
                            {item.link ? (
                              <>
                                {item.title}
                                <i
                                  style={{ fontSize: 10, marginLeft: 5 }}
                                  className="fa fa-external-link"
                                ></i>
                              </>
                            ) : (
                              item.title
                            )}
                          </a>
                        ) : undefined
                      }
                      description={
                        <div style={{ display: "flex" }}>
                          <div>{item.description}</div>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </p>
          </div>
        );
      });
      var skills = this.props.data.skills.map(function (skills) {
        var className = "bar-expand " + skills.name.toLowerCase();
        return (
          <li key={skills.name}>
            <span style={{ width: skills.level }} className={className}></span>
            <em>{skills.name}</em>
          </li>
        );
      });
    }

    return (
      <section id="resume">
        <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Employment</span>
            </h1>
          </div>

          <div className="nine columns main-col">{work}</div>
        </div>

        <div className="row education">
          <div className="three columns header-col">
            <h1>
              <span>Education</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">{education}</div>
            </div>
          </div>
        </div>

        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <p>{skillmessage}</p>

            <div className="bars">
              <ul className="skills">{skills}</ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
